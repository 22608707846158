import styled from 'styled-components'
import {
  fullhd,
  largeBreakpoint,
  mediumBreakpoint,
  xsmallBreakpoint,
} from '../../../config/css-constants'
import { movingBall, scaling } from '../../../assets/keyframes/keyframes'

export const ContactContainer = styled.section`
  padding: 120px 30px 50px 30px;
  position: relative;
  @media all and (min-width: ${xsmallBreakpoint}) {
    padding: 120px 30px 100px;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    padding: 200px 200px 0;
  }
`

export const ContactTitle = styled.h2`
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  font-size: 1.4rem;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 50px;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    height: 2px;
    background: #000;
    bottom: 0;
    left: 0;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    font-weight: 600;
    display: inline-block;
    margin-bottom: 200px;
    text-align: unset;
    &:after {
      width: 150%;
      height: 3px;
    }
  }
`

export const ContactFormWrapper = styled.div`
  @media all and (min-width: ${fullhd}) {
    margin-left: 30%;
    position: relative;
    &:before {
      content: '';
      width: 45px;
      height: 45px;
      display: block;
      border-radius: 50%;
      background: #1c6e7f;
      position: absolute;
      left: -150px;
      top: -20%;
      animation-fill-mode: forwards;
      animation-name: ${movingBall};
      animation-duration: 2s;
      animation-timing-function: ease-in;
      animation-iteration-count: infinite;
    }
  }
`

export const BottomWrapper = styled.div`
  position: relative;
  margin-bottom: 35px;
`

export const BottomDivider = styled.div`
  position: relative;
  margin-bottom: 50px;
  padding: 25.2% 0 0;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 24.6% 0 0;
  }
  &:after {
    content: '';
    background: #397e8d;
    height: 50px;
    width: 100%;
    position: absolute;
    bottom: -45px;

    @media all and (min-width: ${mediumBreakpoint}) {
      bottom: -49px;
    }
  }
`

export const BottomBackground = styled.img`
  width: 100%;
  position: absolute;
`

export const QuotesImage = styled.img`
  position: absolute;
  width: 150px;
  right: 7%;
  bottom: 50px;
  display: none;
  z-index: 2;
  @media all and (min-width: ${largeBreakpoint}) {
    display: block;
  }
`

export const StampImage = styled.img`
  position: absolute;
  width: 100px;
  top: 0;
  right: 10%;
  animation-fill-mode: forwards;
  animation-name: ${scaling};
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  @media all and (min-width: ${largeBreakpoint}) {
    right: 5%;
    top: 30%;
    width: 120px;
  }
`

export const ContactMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 100px 0;
`

export const ContactFormMessage = styled.h2`
  font-size: 1.4rem;
  letter-spacing: 1px;
  color: #397e8d;
  @media all and (min-width: ${mediumBreakpoint}) {
    font-size: 2rem;
  }
`

export const ContactFormMessageAnswer = styled.p`
  color: #397e8d;

  @media all and (min-width: ${mediumBreakpoint}) {
    font-size: 1.4rem;
  }
`
